
import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  name: 'ZonePeriod',
  props: {
    tz: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      date: moment().subtract(1, 'h').format(),
      menu: null,
      timeMenu: null,
      time: moment().format('HH:mm'),
      hours: 1,
      minutes: 0,
      hoursKey: 0,
      minutesKey: 1,
      maxDate: Date.now(),
      timezone: '',
    }
  },
  computed: {
    isCorrect(): boolean {
      return (
        this.minutes <= 59 &&
        this.minutes >= 0 &&
        this.hours >= 0 &&
        this.hours <= 24 &&
        (this.hours !== 0 || this.minutes !== 0)
      )
    },
  },
  methods: {
    setHours(e: FocusEvent) {
      setTimeout(() => {
        ;(e.target as HTMLInputElement).value = this.hours.toString()
      })
    },
    setMinutes(e: FocusEvent) {
      setTimeout(() => {
        ;(e.target as HTMLInputElement).value = this.minutes.toString()
      })
    },
    onWheelBoundsHours(event: WheelEvent) {
      const hour = parseInt((event.target as HTMLInputElement).value)

      if (hour >= 23 && event.deltaY < 0) {
        ;(event.target as HTMLInputElement).value = '0'
      } else if (hour <= 0 && event.deltaY > 0) {
        ;(event.target as HTMLInputElement).value = '24'
      }
    },
    onWheelBoundsMinutes(event: WheelEvent) {
      const minute = parseInt((event.target as HTMLInputElement).value)

      if (minute >= 59 && event.deltaY < 0) {
        // scroll вверх
        ;(event.target as HTMLInputElement).value = '0'
      } else if (minute <= 0 && event.deltaY > 0) {
        // scroll вниз
        ;(event.target as HTMLInputElement).value = '60'
      }
    },
  },
})
